import React from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import useStyles from './contact-styles'

export default function ParentInfoForm() {
  var classes = useStyles()
  const [state, setState] = React.useState({
    name: '',
    email: '',
    member: false,
  })

  function isDisabled() {
    return state.name === '' || state.email === ''
  }

  function handleChange(e) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  function handleCheck(e) {
    setState({
      ...state,
      [e.target.name]: !state[e.target.name],
    })
  }

  return (
    <>
      <Typography className={classes.heading} variant="h1">
        Parent Info Night Form
      </Typography>
      <form
        className={classes.form}
        name="parentInfo"
        method="POST"
        data-netlify="true"
      >
        <TextField
          required
          id="name"
          label="Name"
          name="name"
          variant="filled"
          onChange={handleChange}
          className={classes.input}
          required={true}
        />
        <TextField
          required
          id="email"
          label="Email"
          name="email"
          variant="filled"
          onChange={handleChange}
          className={classes.input}
          required={true}
        />
        <Typography className={classes.label}>
          <label htmlFor="member">Are you a member of the NHDSA?</label>
        </Typography>
        <Checkbox
          id="member"
          name="member"
          onChange={handleCheck}
          checked={state.member}
        />
        <input type="hidden" name="form-name" value="parentInfo" />
        <Button
          type="submit"
          variant="contained"
          className={classes.button}
          disableFocusRipple
          disabled={isDisabled()}
        >
          Submit
        </Button>
      </form>
    </>
  )
}
