import React from 'react'
import Layout from 'components/layout/layout'
import ParentInfoForm from 'components/forms/parentInfo'

export default () => {
  return (
    <Layout>
      <ParentInfoForm />
    </Layout>
  )
}
